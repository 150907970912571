@font-face {
  font-family: "Open Sans";
  src: url("./Open_Sans/static/OpenSans-Light.ttf");
  font-style: normal;
  font-weight: normal;
}
body {
  margin: 0;
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(236, 236, 236);
}



textarea{
  resize: none;
}

img,
canvas {
  pointer-events: none;
}

input, textarea{
  background-color: #FFFFF0;
}


/*Основа*/
.contentFlexWrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  height: 90vh;
}


header ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
h2 {
  margin: 20px;
}

.popupTreeBody h2{
  margin-left: 5px;
}
.contentItemDscription li {
  padding: 1.5% 0;
}
.popup {
  background-color: white;
  z-index: 2;
  position: absolute;
  display: none;
  width: 100%;
  padding-bottom: 2%;
}
.saleinfoFlexRow {
  display: flex;
  justify-content: space-around;
  width: 70%;
}

.productsInWrap {
  width: 100%;
  height: calc(445.5px + 445.5 * (100vh / 1000));
  overflow-y: scroll;
}

.popupClose {
  font-size: calc(12px + 12 * (100vw / 1920));
  width: 8%;
  display: flex;
  justify-content: space-around;
}

.popupImg img {
  width: calc(148px + 444 * (100vw / 1920));
  height: calc(222.75px + 668.25 * (100vh / 1000));
}
.popupImg {
  max-height: calc(222.75px + 668.25 * (100vh / 1000));
}
.popupWrap {
  display: flex;
}
.sliderBued {
  position: absolute;
  right: 3%;
  top: 2%;
  display: none;
}

/*Карточка товара*/

/*Адаптив*/
@media (max-width: 1600px) {
  .navMenu.top img,
  .collectionTitle img {
    height: 80%;
  }
}

@media (max-width: 1490px) {
  .navMenu.top img,
  .collectionTitle img {
    height: 70%;
  }
  .sizePl {
    width: 100%;
    margin: 1px 0;
  }
 

  .contentItem.flex30 {
    font-size: calc(5px + 10 * (100vw / 1920));
  }
}
@media (max-width: 1291px) {
  .navMenu.top {
    width: 45%;
  }

  .navMenu.top img,
  .collectionTitle img {
    height: 70%;
  }

  .btnIcrement {
    width: 20px;
    height: 20px;
  }
  .sizePl.pl30 {
    padding: 3%;
  }
}
@media (max-width: 1099px) {
  .navMenu.top img,
  .collectionTitle img {
    margin-right: 10%;
    height: 80%;
  }
  .vision {
    display: none;
  }
}
@media (max-width: 930px) {
  .sizeItem {
    min-width: 30%;
  }
  .body {
    width: 100%;
    min-width: 100%;
  }


  .contentItem {
    width: 100%;
  }

  .contentItemImage {
    width: 50%;
    height: auto;
  }
  .container {
    margin-top: 30px;
  }

  .sizeBtnsWrapper {
    max-width: 180px;
  }

  .navMenu.top {
    width: 50%;
  }

  .contentItem.flex30 {
    display: flex;
  }
}
@media (max-width: 768px) {
  .contentItemDscription li {
    padding: 0;
  }
 



  .saleinfoFlexRow {
    flex-wrap: wrap;
  }
  .saleinfo {
    height: 50px;
  }
  .mobilHide {
    display: none;
  }
  .mobilShow {
    width: 100%;
    font-size: 10px;
  }
  .saleinfobtnblock {
    width: 50%;
  }
  .saleinfoFlexRow {
    width: 50%;
  }

 
  .container {
    margin-top: 35px;
  }

  .popupWrap {
    display: block;
    max-height: calc(222.75px + 668.25 * (100vh / 1000));
  }

  .popupImg img {
    width: 100%;
    height: 100%;
  }

  .sizeFlexWrap {
    justify-content: left;
  }
 
  .line img {
    width: 23%;
    padding: 6px 0;
  }


  .collectionMobile {
    display: initial;
    width: 300%;
  }
 
  .contentItem {
    width: 100%;
    display: block;
  }
  .contentItemImage {
    width: 100%;
    display: block;
    height: auto;
  }
  .sizeItem {
    min-width: 11%;
    margin: 2%;
  }

  .contantItemInfo {
    width: 100%;
    display: block;
  }

  .contentItemDescription ul {
    width: 100%;
    display: inline-flex;
    justify-content: flex-start;
  }
  

  .navMenu.top.top {
    display: none;
  }
  .collectionTitle,
  .headLine {
    display: none;
  }
}

/*Нижняя панель*/
.saleinfo {
  display: flex;
  background-color: white;
  height: calc(50px + 40 * (100vw / 1920));
  align-items: center;
  justify-content: space-around;
  text-align: left;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  font-size: calc(6px + 14 * (100vw / 1920));
  padding: 0 5px 0 5px;
}
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 100%;
}

/*Профиль пользовател/*/

.userProfile {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 1% 2%;
}

.userDataInput {
  width: 90%;
  height: 70px;
  font-size: calc(20px * (100vw / 1920));
  margin: 20px;
  border-radius: none;
  border: 1px solid black;
  padding: 0 40px;
}

.login-input {
  width: 88.5%;
  margin: 2% 0;
  height: 40px;
  font-size: 20px;
  text-align: center;
  border: none;
  border-bottom: 1px solid black;
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

