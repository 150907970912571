.AdminPanelWrapper {
  width: 100%;
  height: 100%;
  display: flex;
}
.AdminPanelWrapper table {
  width: 100%;
}
.AdminPanelWrapper tbody {
  min-height: 45vh !important;
}
.AdminPanelMenu {
  display: flex;
  justify-content: space-between;
  width: 20vw;
  height: 7vh;
  left: -0.05%;
  position: absolute;
  top: calc(-1% - 30 * (100vw / 1920));
  height: calc(1% + 30 * (100vw / 1920));
}
.AdminDescTop {
  padding: 1%;
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translate(-50%);
  background-color: white;
  width: 85vw;
  height: 70vh;
  border: 1px solid black;
}
.ListBtn {
  color: black;
  background-color: white;
  width: 33.3%;
  border: 1px solid black;
  cursor: pointer;
  font-size: calc(5px + 11 * (100vw / 1920));
}
.selectedList {
  border-bottom: none;
}
.wikiButton.min {
  width: 23%;
}
.AdminPanelContent {
  margin-top: 2%;
  width: 70.5%;
  padding: 0 1.6%;
  height: 80%;
  display: flex;
  overflow-x: hidden;
}

.TableWrapper {
  max-height: 90%;
  width: 44%;
  overflow: hidden;
  padding: 15px;
  border: 1px solid black;
  padding-bottom: 70px;
}
.TableWrapper .scroll {
  min-height: 45vh;
  border: 0.1em solid gray;
  border-top: none;
  border-collapse: collapse;
  background-color: #e9e9e9;
  padding-bottom: 70px;
}
.TableWrapper:first-child {
  border-right: none;
}
.PopupSelect {
  padding: 2%;
  padding-bottom: 5%;
  z-index: +22;
  width: 600px;
  min-height: 100px;
  max-height: 30%;
  border: 1px solid black;
  background-color: white;
  position: fixed;
  left: 50%;
  top: 30%;
  overflow: hidden;
  transform: translate(-50%, -50%);
  opacity: 100%;
}
.PopupSelect table {
  width: 100%;
  min-height: 100%;
  background-color: white;
}
.scroll {
  max-height: 45vh;
  overflow-y: scroll;
  padding-bottom: 70px;
}
.modalWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: gray;
  opacity: 90%;
}
.AdminPanelWrapper th,
.AdminPanelWrapper td {
  font-size: calc(5px + 11 * (100vw / 1920));
}
.AdminPanelWrapper td {

}

.AdminPanelWrapper th {
  border-top: 2px solid white;
  border-top: 2px solid gray;
  background-color: black;
  color: white;
}
