.HeaderMenu {
  position: absolute;
  top: 50px;
  z-index: 10;
  background-color: white;
  border: 1px solid rgb(216, 216, 216);
  border-top: none;
  font-size: 16px;
 
}

.HeaderMenu:hover{
  display: block;
}

.noActiv {
  display: none;
}
.HeaderMenu ul{
  width: 100%;
  display: block;
  overflow: hidden;
}
.HeaderMenu li:hover {
  cursor: pointer;
  background-color: rgb(190, 228, 250);
}
.HeaderMenu li {
  padding:  0 20px;
  width: 100%;
}
