.contentItem {
  display: flex;
  width: 100%;
  font-size: calc(2px + 14 * (100vw / 1920));
  margin: 5px;
  min-height: 30vh;
  background-color: white;
  max-height: 75vh;
  font-family: "Open Sans";
}
.contentItem li,
.contentItem ul {
  list-style-type: none;
  padding: 0;
}
.accordeonBtn {
  width: 20px;
  height: 20px;
}
.rotateClass {
  transform: rotate(90deg);
}
.sizeBoxWrapper{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.borderTop{
  padding: 1vh 0 0 0;
  margin-top: 2vh;
  border-top: 1px solid black;
}
.sizeBoxInput{
  width: 50%;
  border: none;
  background-color: rgb(250, 240, 240);
}
.ProductAccordeonWrapper{
  margin-top: 3vh;
}
.sizeBox{
  border: 1px solid black;
  width: calc(20px + 20 * (100vw / 1920));
  height: calc(20px + 20 * (100vw / 1920));
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1%;
}
.accordeonBtn:hover {
  cursor: pointer;
}
/*слайдер*/
.sliderline {
  display: flex;
  background-color: rgba(30, 30, 30, 0.1);
  position: relative;
  z-index: 5;
  width: 98%;
  justify-content: space-between;
}
.sliderlineItem {
  background-color: black;
  height: 2px;
  width: auto;
  z-index: 4px;
}

.sliderbtns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}
.contentAccordeon {
  overflow-y: scroll;
}
.contentItemImage {
  position: relative;
  z-index: 0;
  text-align: center;
  width: 65%;
  height: 100%;
}
.searchInputBlock {
  display: inline-flex;
}
.contentItemImage img {
  height: auto;
  max-height: 99%;
  width: 98%;
}
.sizeCout {
  width: 100%;
  text-align: center;
}
.slider {
  transition: all ease 1s;
  height: 100%;
  width: 100%;
}
/*описание товара*/
.siceTh {
  display: flex;
  justify-content: space-between;
  width: 90%;
  padding-bottom: 5%;
}

.sizePl {
  text-align: center;
  display: block;
  border: 1px solid black;
  border-radius: 100px;
  padding: calc(2px + 4 * (100vw / 1920));
  font-size: calc(6px + 14 * (100vw / 1920));
}
.sizeItem {
  min-width: 40%;
  max-width: 50%;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}
.sizeFlexWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.sizes li {
  margin: 5% 0;
}

.sizes {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contentItemInfo {
  display: flex;
  flex-direction: column;
  margin: 0 2% 0 2%;
  width: 50%;
  padding: 0;
}

.contentItemImage {
  width: 50%;
  height: auto;
}

.sizeBtnsWrapper {
  display: flex;
  justify-content: space-between;
  margin: 5% 0;
  height: auto;
  width: 100%;
  align-items: center;
}

.btnIcrement {
  border: 1px solid black;
  width: calc(15px + 17 * (100vw / 1920));
  height: calc(15px + 17 * (100vh / 1080));
  font-size: calc(5px + 13 * (100vw / 1920));
  background-color: white;
  margin-left: 7px;
}

.btnIcrement:active{
  background-color: black;
  color: white;
}

.blackBtn {
  background-color: black;
  color: white;
}
.blackBtn {
  background-color: black;
  color: white;
}
.catrtBtnBlock{
  display: flex;
  justify-content: space-between;
}
.catrtBtn {
  width: 49%;
  border: 1px solid black;
  background-color: black;
  color: white;
  margin: 20px 0;
  padding: 2%;
  font-size: calc(2px + 14 * (100vw / 1920));
  font-weight: 300;
}
.catrtBtn:hover, .catrtBtn.white:hover{
background-color: gray;
color: white;
}
.catrtBtn.white{
  background-color: white;
  border: 1px solid black;
  color: black;
}
@font-face {
  font-display: swap;
  font-family: rouble;
  font-style: normal;
  font-weight: 400;
  src: url(../../../../public/static/fonts/rouble.woff2) format("woff2");
}

.rub {
  height: calc(2px + 10 * (100vw / 1920));
}
.sliderBtn {
  height: calc(10px + 30 * (100vw / 1920));
  line-height: calc(5px + 3 * (100vw / 1920));
  width: calc(10px + 30 * (100vw / 1920));
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: calc(3px + 5 * (100vw / 1920));
  background-color: white;
  border: none;
}
.contentItemDscription li {
  padding: 1.5% 0;
}
.contentItemDscription ul {
  height: 100%;
  margin: 0;
}
.btn30 {
  width: calc(2px + 23 * (100vw / 1920));
  height: calc(2px + 23 * (100vh / 1000));
}
.active {
  background-color: black;
  color: white;
  border: none;
}
/*описание товара*/
.siceTh {
  display: flex;
  justify-content: space-between;
  width: 90%;
  padding-bottom: 5%;
}

.sizePl {
  text-align: center;
  display: block;
  border: 1px solid black;
  border-radius: 100px;
  padding: calc(2px + 4 * (100vw / 1920));
  font-size: calc(6px + 14 * (100vw / 1920));
}
.sizeItem {
  min-width: 40%;
  max-width: 50%;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}
.sizeFlexWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.sizes li {
  margin: 5% 0;
}

.sizes {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}




.sizeCout {
  width: 100%;
  text-align: center;
}
.spinner-border {
  display: inline-block;
  width: 6rem;
  height: 6rem;
  border: 0.25em solid #333;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100; /* Знак "+" не нужен */
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.visually-hidden {
  position: absolute;
  width: 1px; /* Исправлено значение ширины */
  height: 1px; /* Исправлено значение высоты */
  margin: -1px; /* Добавлено для скрытия элемента */
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap; /* Добавлено для корректного скрытия текста */
}
@media (max-width:500px) {
  .sizeBox{
    width: calc(20px + 20 * (100vw / 1920));
    height: calc(10px + 15 * (100vh / 700));
  }
 
  .contentItem{
    margin: 0;
    padding: 0;
  }
  .catrtBtn {
    font-size: calc(14px + 2 * (100vw / 1920));
  }
}

  
  @media (max-width:550px) {
  .rub{
    
      height: calc(5px + 3.5 * (100vw / 320));
    
  }
}
  
