.Editor {
  width: 100%;
  overflow-x: scroll;
  background-color: rgb(236, 236, 236);
  border-radius: 7px;
  height: 100%;
  padding: 15px;
  padding-bottom: 0;
  padding-top: 0;
}
.Editor.col {
  justify-items: center;
  width: 100%;
  display: grid;
  grid-column: 20fr;
  grid-column-gap: 10%;
  grid-template-columns: 100%;
  justify-content: center;
  align-items: center;
  overflow-x: scroll;
  background-color: rgb(236, 236, 236);
  border-radius: 7px;
  height: 100%;
}

.redactionBtn {
  background-color: white;
  border: 1px solid rgb(185, 180, 180);
  margin-top: 10px;
  padding: 5px;
}
.redactionBtn:hover {
  background-color: rgb(215, 233, 243);
  cursor: pointer;
}

.redactionInfo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  line-height: 15px;
  margin: 5px;
}
.HTMLPage h1 {
  font-size: 31px;
  line-height: 22px;
  font-weight: 200;
}
.redactionInfoSpan {
  padding: 5px;
}
.react-pdf__Page__textContent.textLayer,
.react-pdf__Page__annotations.annotationLayer {
  display: none;
}
.HTMLPage {
  margin: 10px 5px;
  padding: 50px 5% 0 5%;
  background-color: white;
  min-height: 100%;
  font-weight: 200;
  font-family: "Open Sans";
  font-size: calc(2px + 12 * (100vw / 1920));
}

.HTMLPage a {
  color: blue;
  text-decoration: dashed;
}

.HTMLPage img {
  max-width: 80%;
  height: auto;
}
.HTMLPage h4 {
  font-size: calc(2px + 14 * (100vw / 1920));
  line-height: 19.07px;
  font-weight: 300;
  align-items: center;
  padding: 0;
}
.HTMLPage h1 {
  font-size: calc(2px + 20 * (100vw / 1920));
  text-align: center;
}
.HTMLPage h2 {
  font-size: calc(2px + 18 * (100vw / 1920));
}
.HTMLPage h3 {
  font-size: calc(2px + 16 * (100vw / 1920));
}
.HTMLPage h5 {
  font-size: calc(2px + 14 * (100vw / 1920));
}
.HTMLPage h2 {
  font-size: calc(2px + 14 * (100vw / 1920));
}
.HTMLPage h2 {
  font-size: calc(2px + 14 * (100vw / 1920));
}
.cm-line {
  font-size: calc(2px + 10 * (100vw / 1920));
}
