.contentFlexWrapper.flex {
  display: grid;
  grid-template-columns: 6fr 6fr;
  gap: 1vh;
  width: 98%;
  height: 100%;
  min-height: 100vh;
  overflow: scroll;
  background-color: white;
  margin-top: calc(5% + 90px);
  padding: 1%;
}
hr{
  line-height: 1px;
  padding: 0;
  height: 1px;
  border: none;
  border-bottom: 1px solid black;
}

@media (max-width: 900px) {
  .contentFlexWrapper.flex {
    grid-template-columns: 12fr;
    gap: 20px;
    margin-top: 18%;
  }
  .contentAccordeon {
    max-height: 70%;
  }
  .sliderBtn{
    height: calc(30px + 10 * (100vw / 1920));
      line-height:  calc(5px + 3 * (100vw / 1920));
    width: calc(30px + 10 * (100vw / 1920));
    padding: calc(3px + 5 * (100vw / 1920));
    background-color: white;
    border: none;
  }
  .contentItem {
    max-height: none;
  }
}

@media (max-width: 778px) {
  .contentFlexWrapper.flex {
    margin-top: 18%;
  }
}
@media (max-width: 609px) {
  .contentFlexWrapper.flex {
    margin-top: 20%;
  }
}

@media (max-width: 500px) {
  .contentFlexWrapper.flex {
    margin-top: 28%;
  }
  .contentItem {
    display: block;
    width: 100%;
    font-size: calc(12px + 2 * (100vw / 1920));
  }
  .contentItemImage{
    width: 99%;
  }
  .contentItemInfo {
    width: 98%;
  }
 
 
 
}
@media (max-width: 350px) {
  .contentFlexWrapper.flex {
    margin-top: 33%;
  }
}
