.MainRightToolbar {
  border-radius: 7px;
  min-height: 100%;
  background-color: white;
  font-size: calc(2px + 12 * (100vw / 1920));
  
}
.toolbarTable {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.ToolbarTableStringTags {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.ToolbarTableString {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;
  overflow: scroll;
  max-height: 75vh;
}
.Tag {
  padding: 5px;
  margin: 2px;
  background-color: rgb(190, 228, 250);
  text-align: center;
  font-size: 12px;
  border-radius: 7px;
}
.ToolbarTableItem {
  text-align: center;
  border: 1px solid black;
}
select {
  width: 150px;
  font-size: 16px;
}
.MainRightToolbar table {
  width: 100%;
}
table {
  border-collapse: collapse;
  border: 1px solid rgb(201, 197, 197);
}
.MainRightToolbar th,
.MainRightToolbar td {
  font-size: calc(5px + 5 * (100vw / 1920)); 
}
th, td{
  border: 1px solid rgb(201, 197, 197);
  text-align: center;
}
.MainRightToolbar th,
.MainRightToolbar td {
  font-size: calc(5px + 11 * (100vw / 1920));
}

.slct:hover {
  cursor: pointer;
  background-color: rgb(190, 228, 250);
}
.inputFileLabel {
  margin: 10% 0;
}
.input__file {
  display: none; /* Скрыть стандартный input */
}

.custom-file-upload {
  border: 1px solid #000; /* Черная рамка */
  padding: 0 5px;
  background-color: #fff; /* Белый фон */
  color: #000; /* Черный текст */
  cursor: pointer;
}
.input__wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
}

.custom-file-upload:hover {
  background-color: #000; /* Изменение цвета при наведении */
  color: #fff;
}

.articleToolbarBtn {
  margin: 5px;
  background-color: black;
  border: none;
  width: calc(10px + 20 * (100vw / 1920));
  height: calc(10px + 20 * (100vw / 1920));
  position: relative;
}
.articleToolbarBtn.r img {
  width: 80%;
  height: 80%;
  position: absolute;
  left: 57%;
  top: 45.1%;
  transform: translate(-50%, -50%);
}
.articleToolbarBtn img {
  width: 70%;
  height: 70%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
