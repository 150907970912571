.TreeContextMenu{
    width: 15vw;
    border: 1px solid black;
    position: fixed;
    z-index: 10;
    display: none;
    background-color: white;
    padding: 0;
    
}


.TreeContextMenu li{
    list-style-type: none;
}
.TreeContextMenu ul{
    padding: 10px 2%;
    margin: 0;
}
.TreeContextMenu li:hover{
    cursor: pointer;
    background-color: rgb(190, 228, 250);
}