.sectionItemWrapper{
    max-width: 100%;
    border: 1px solid black;
    padding: 2% 6%;
    background-color: white;
    height: 20%;
    max-height: 200px;
    margin-bottom: 3%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: calc(2px + 12 * (100vw / 1920));
}
.SectionPageWrapper{
    width: 55%;
    display: grid;
    grid-template-columns: 6fr 6fr;
    gap: 20px;
    padding: 1%;
    overflow-y: scroll;
}

.SectionPageWrapper h2{
    margin-left: 0;
    font-size: calc(10px + 10 * (100vw / 1920));
}
.toLink:hover{
    cursor: pointer;
}
.toLink{
    text-decoration: underline;
}
.sectionItemDescription{
    min-height: 22%;
}
.smallItemText{
    font-size: calc(5px + 5 * (100vw / 1920));
}
.sectionItemTitle{
    display: flex;
    align-items: center;
}
.sectionItemTitle img{
    height: 30%;
    margin-right: 10px;
    width: auto;
}
.sectionItemButtons{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.sectionSections, .sectionArticles{
    max-width: 26vw;
}