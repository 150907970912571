.opt_popup_wrapper {
  width: 70%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid black;
  z-index: 5;
  padding: 0 2% 2% 2%;
  max-height: 100vh;
  opacity: 100%;
}
.grayfone {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(128, 128, 128, 0.7);
  z-index: 4;
  overflow: scroll;
}
.opt_popup_wrapper .sliderline {
  display: none;
}
.flexLi {
  display: flex;
  justify-content: space-between;
}
.popupClose:hover {
  cursor: pointer;
}
.popupClose {
  margin: 2% 0 1% 0;
  width: 10%;
  display: flex;
  align-items: center;
  letter-spacing: calc(1pt + 4 * (100vw / 1920));
  justify-content: space-between;
  font-size: calc(1px + 15 * (100vw / 1920));
}

.popupClose h1 {
  display: none;
}
.leftHide {
  display: none;
}
.popupClose img {
  height: calc(1px + 15 * (100vw / 1920));
  width: auto;
  padding: 0;
}
.opt_popup_wrapper_flex {
  display: grid;
  grid-template-columns: 3fr 5fr;
}

.capsulImg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.popupCloseMini {
  font-size: calc(10px + 10 * (100vw / 1920));
  display: flex;
  width: 12%;
  align-items: center;
  justify-content: space-between;
}
.popupCloseMini:hover {
  cursor: pointer;
}
.popupCloseMini img {
  height: calc(10px + 10 * (100vw / 1920));
  width: auto;
}
.capsulImg img {
  width: auto;
  max-height: 80vh;
}
.products {
  text-align: left;
  background-color: white;
  padding-right: 2%;
  overflow-y: scroll;
  max-height: calc(45px + 690 * (100vw / 1920));
}
.bigImg {
  height: calc(50px + 753.6 * (100vw / 1920));
}
.opt_popup_wrapper .contentItem {
  margin: 0;
  display: grid;
  grid-template-columns: 3fr 6fr;
  height: calc(50px + 700 * (100vw / 1920)/2);
  font-size: calc(2px + 10 * (100vw / 1920));
}

.opt_popup_wrapper .contentItem li {
  margin: 0.5% 0;
}

.opt_popup_wrapper .contentItem li:last-child {
  margin-bottom: 0;
}
.opt_popup_wrapper .contentItem:last-child {
  margin-bottom: 0;
}
.opt_popup_wrapper .contentItemImage {
  width: auto;
  max-width: 100%;
  margin-top: 0;
}
.opt_popup_wrapper .borderTop {
  margin-top: 2vh;
}
.opt_popup_wrapper .contentItemInfo {
  width: 80%;
 
  
}
.opt_popup_wrapper .catrtBtn {
  margin-bottom: 0;
  font-size: calc(2px + 10 * (100vw / 1920));
  padding: calc(1% + 2 * (100vw / 1920));
}
.opt_popup_wrapper .contentItemInfo li {
  padding: 2px;
}
.opt_popup_wrapper .contentItemInfo{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.opt_popup_wrapper .ProductAccordeonWrapper {
  display: none;
}
.opt_popup_wrapper .flexLi {
  display: flex;
  justify-content: space-between;
}

.opt_popup_wrapper .sliderbtns {
  display: none;
}
.opt_popup_wrapper .contentItem {
  min-height: auto;
  
}

.opt_popup_wrapper_flex .sizeBox {
  width: calc(10px + 30 * (100vw / 1920));
  height: calc(10px + 30 * (100vw / 1920));
}

@media (max-width: 980px) {
  .opt_popup_wrapper {
    height: auto;
    max-height: none;
  }
}


@media (min-width: 600px) {
    .opt_popup_wrapper .sizeBox{
        width:  calc(10px + 30 * (100vw / 1920));
        height: calc(10px + 30 * (100vw / 1920));
    }
    .opt_popup_wrapper .contentItem, .opt_popup_wrapper .sizeBoxInput{
        font-size: calc(2px + 12 * (100vw / 1920));
    }
    .opt_popup_wrapper .contentItem li{
        margin-bottom: calc(1px + 1 * (100vw / 1920));
    }
    .opt_popup_wrapper .borderTop{
        margin-top: calc(1px + 5 * (100vw / 1920));
    }
    .opt_popup_wrapper .catrtBtn{
        margin-top: calc(1px + 5 * (100vw / 1920));
    }
    .opt_popup_wrapper .contentItemInfo{
        max-height: calc((50px + 700 * (100vw / 1920))/2);

    }
  }

@media (max-width: 977px) {
  
}
@media (max-width: 680px) {
  .opt_popup_wrapper_flex .contentItem {
    font-size: calc(2px + 8 * (100vw / 320));
    display: flex;
    margin-left: 0;
    max-height: none;
  }
  .products {
    height: auto;
  }
  .popupCloseMini {
    width: 13%;
  }
  .opt_popup_wrapper {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
    max-height: none;
  }
  .opt_popup_wrapper_flex .contentItemImage {
    width: 55%;
  }
  .opt_popup_wrapper_flex .contentItem{
    height: auto;
  }
  .products {
    max-height: none;
  }
  .opt_popup_wrapper_flex .borderTop {
    margin-top: 1%;
  }

  .opt_popup_wrapper_flex .sizeBoxInput {
    font-size: calc(2px + 2 * (100vw / 320));
  }
  .opt_popup_wrapper_flex .rub {
    height: calc(2px + 3 * (100vw / 320));
  }
  .opt_popup_wrapper_flex .contentItemInfo {
    font-size: calc(2px + 3 * (100vw / 320));
    max-height: none;
    min-height: 20vh;
  }
  .opt_popup_wrapper_flex .contentItemImage {
    max-height: none;
  }
  .opt_popup_wrapper .catrtBtn {
    margin: 10px 0;
    padding: 2%;
    font-size: calc(2px + 5 * (100vw / 320));
  }
  .opt_popup_wrapper {
    width: 100%;
    padding: 0;
  }

  .opt_popup_wrapper_flex {
    display: block;
    padding: 0 4%;
  }

  .capsulImg,
  .products {
    max-height: none;
    width: 100%;
  }
  .capsulImg img {
    width: 100%;
    height: auto;
    max-height: none;
    margin-bottom: 4%;
  }

  .popupClose p {
    display: none;
  }
  .popupClose {
    width: 92%;
    padding: 0 4%;
    display: flex;
    justify-content: space-between;
    height: 5%;
    align-items: center;
    font-size: calc(20px + 6 * (100vw / 1920));
  }
  .popupClose img {
    height: calc(20px + 10 * (100vw / 1920));
  }

  .leftHide {
    width: 10%;
    display: block;
  }
  .popupClose h1 {
    display: block;
    font-size: calc(20px + 10 * (100vw / 1920));
    padding: 0;
    line-height: calc(20px + 10 * (100vw / 1920));
    margin-right: 0;
    font-weight: 400;
  }
}
