.strong {
  font-size: calc(5px + 11 * (100vw / 1920));
  
}
.TreeItem:hover,
.TreeGroupItemTitle :hover {
  cursor: pointer;
  background-color: rgb(215, 233, 243);
  
}
.TreeGroupItem {
  position: relative;
  align-items: center;
  text-align: left;
  max-width: 100%;
  overflow-x: hidden;
  
 
}
.TreeGroupItemTitle {
  font-size: calc(5px + 7 * (100vw / 1920));
  align-items: center;
  display: inline-flex;
  width: 100%;
  
}
.select {
  background-color: rgb(190, 228, 250);
}
.TreeGroupItem img {
  margin-right: calc(1px + 4 * (100vw / 1920));
  width: calc(4px + 8 * (100vw / 1920));
  height: auto;
}
.TreeItem img {
  width: calc(4px + 8 * (100vw / 1920));
  height: auto;
  margin-right: calc(1px + 4 * (100vw / 1920));
  margin-left: 1px;
}
.TreeGroupItem hr {
  width: 8px;
  border: none;
}
.TreeItem {
  font-size: calc(5px + 11 * (100vw / 1920));

  align-items: center;
  display: inline-flex;
   width: 100%;
}
.TreeOpenButton {
  border-radius: 0;
  color: white;
  background-color: black;
  border: none;
  min-width: 0.6vw;
  width: calc(4px + 8 * (100vw / 1920));
  height: calc(4px + 8 * (100vw / 1920));
  font-size: calc(1px + 9 * (100vw / 1920));
  padding: 0;
  margin-right: 5px;
  line-height: calc(2px + 5 * (100vw / 1920));
}
.TreeBody {
  display: none;
  width: 97%;
  position: relative;
  left: 15px;
  
}
.TreeBody::before {
  content: '';
  border-left: 1px solid #000;
  position: absolute;
  height: calc(100%  - calc(1px + 7px + (100vw / 1920)));
  margin-bottom: 40px;
  left: 0;
  
 
}

.acActive {
  display: block;
}
