


    #myqrreader{
        font-size: 40px;
    }
    #myqrreader button{
        width: 600px;
        height: 70px;
        font-size: 40px;
    }
    #myqrreader img{
        width: 600px;
        height: auto;
        font-size: 20px;
    }
