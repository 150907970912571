.PersonelFormWrapper {
  padding: 20px;
  background-color: white;
}
.PersonelFormWrapper select, .PersonelFormWrapper option{
  background-color: #FFFFF0;;
    border: 1px solid black;
    font-size: 15px;
    height: 24px;
}
.PopupSelect input{
  margin: 20px 0;
  width: 50%;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.haveSelect{
    display: flex;
    justify-content: space-between;
    width: 26%;
}



.row {
  display: grid;
  gap: 20px;
  padding-bottom: 20px;
  align-items: end;
  justify-items: space-between;
}
.TitleBlock {
  display: flex;
  align-items: baseline;
}
.TitleBlock input{
    width: 100px;
    border-radius: none;
    border: 1px solid black;
    
}
.HaveBtn{
    display: flex;
    justify-content: space-between;
    width: 100%;
   
}
.HaveBtn >.popupBtn{
    width: 22px;
    height: 24px;
}

.inputblock input {
  width: 100%;
  border-radius: none;
  border: 1px solid black;
  height: 20px;
}
.inputblock input[type='checkbox'] {
    width: 43%;
    border-radius: none;
    border: 1px solid black;
    height: 20px;
  }

.OneItemhaveCheckBox{
    grid-template-columns: 4.1fr 2fr 6fr;
}
.OneItemhaveCheckBoxLong{
    grid-template-columns: 3.3fr 1fr 6fr;
}
.TwoItemhaveCheckBox{
    grid-template-columns: 4fr  8fr;
}
.inputblock textarea {
  width: 100%;
  height: 100px;
  resize: none;
  border-radius: none;
  border: 1px solid black;
}

.inputblockTwo.row {
  grid-template-columns: 6fr 6fr ;
}


.TitleBlock h1 {
  font-size: 22px;
}
.row.OneItem {
  grid-template-columns: 4fr;
}
.row.OneItemMin {
  grid-template-columns: 4fr 7fr;
}
.row.TwoItem {
  grid-template-columns: 3fr 3fr 6fr;
}
.haveCheckBox{
    display: flex;
    justify-content: space-between;
}
.columnTwoItemMin {
  grid-template-columns: 3fr;
}
.row.ThreeItemMin {
  grid-template-columns: 3.5fr 3fr 3.5fr 3.5fr;
  align-items: baseline;
}
.row.TwoItemMin {
    grid-template-columns: 3fr 3fr 6fr;
  }
.row.ThreeItemLastMin {
  grid-template-columns: 4.5fr 4.35fr 3fr;
}
.row.ThreeItem {
  grid-template-columns: 4fr 4fr 4fr;
  align-items: baseline;
}
.HaveBtn >input{
  width: 100%;
}