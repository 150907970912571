.cm-theme-light {
  width: 100%;
  height: 100%;
}
.colorToolbar {
  display: none;
}
.editorToolbar {
  width: 96%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: first baseline;
  
  background-color: white;
  border-radius: 5px;
  padding: 2%;
  max-height: 80vh;
}

.articleName {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
}
.nameInput {
  width: 90.6%;
  margin-left: 10px;
}
.toolbarBtns {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: left;
  background-color: rgb(218, 214, 210);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  height: 50px;
  align-items: center;
}
.toolbarBtns > select {
  border: none;
  margin: 5px;
  background-color: rgb(218, 214, 210);
  font-size: 12px;
  width: 11%;
}
.toolbarBtns > button {
  border: none;
  margin: 5px;
  background-color: rgb(218, 214, 210);
  font-size: 12px;
}
.toolbarBtns > button:hover {
  cursor: pointer;
  padding: 0 10px 0 10px;
  font-size: 15px;
}
.toolbarBtns > select:hover,
.toolbarBtns > option:hover {
  cursor: pointer;
  width: 12%;
}
.delimiter {
  font-size: 18px;
  font-weight: normal;
  color: gray;
}
.colorinput {
  width: 30px;
  height: 15px;
  border: none;
  background-color: rgb(218, 214, 210);
}
@media (max-width: 1313px) {
  .cm-theme-light,
  .editorToolbar {
    width: 100%;
  }
}
.article_wrapper_block {
  position: relative;
}
.article_wrapper_block h6 {
  margin: 0;
}

.editorToolbar.select {
  width: 70%;
  height: 100%;
  margin: 0;
  background-color: white;
  padding: 2px;
  border-radius: 0;
}
