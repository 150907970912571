.TreeWrapper {
  height: 100%;
  padding: 5px;
  width: 100%;
  border-radius: 7px;
  justify-content: space-between;
  overflow: hidden;
  background-color: white;
  overflow-y: scroll;
  min-width: 15vw;
  position: relative;
 
}
header{
  user-select: none;
}
.rightToolbar,.leftToolbar{
  background-color: white;
  justify-content: space-between;
  width: 20vw;
  min-width: 16vw;
}

.Treeheader {
  border-radius: 5px;
  padding: 5px;
  max-width: 20vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.TreeheaderBtn {
  width: 34px;
  height: 34px;
  padding: 0;
}
.TreeheaderBtn img {
  width: 30px;
  height: 30px;
  margin: 0;
}
.wikiButton{
  border: none;
  font-size: calc(2px + 12 *(100vw / 1920));
  width: 30%;
  padding: 1%;
  background-color: rgba(30, 30, 30, 1);
  color: white;
}
.wikiButton>a{
  color: white;
}
@media (max-width:824px) {
  .rightToolbar, .leftToolbar {
    min-width: 28%;
  }
}
@media (min-width:1921px) {
  .wikiButton{
    max-height: none;
  }
}
.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline, .ck-focused, .ck.ck-editor__main{
  height: 100%;
  min-height: 100vh;
}
.gutter {
  width: 15px;
  height: 100%;
  background: white;
  cursor: col-resize;
}