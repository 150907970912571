/* Шапка*/
a {
  color: black;
  text-decoration: none;
}
/*Логотип*/
.logo img {
  align-items: center;
  height: calc(7px + 12 * (100vw / 1920));
  width: auto;
}
.collection h1 {
  font-size: calc(10px + 14 * (100vw / 1920));
  line-height: calc(10px + 23 * (100vw / 1920));
  letter-spacing: 5pt;
}
header ul {
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
}
header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: white;
  border-bottom: none;
  margin-bottom: 100px;
}
header li,
footer li {
  display: inline-flex;
  font-size: calc(7px + 10 * (100vw / 1920));
}
header li img,
footer li img {
  margin: 0 10px 0 0;
  height: calc(2px + 20 * (100vw / 1920));
  width: auto;
}
.contentBtns {
  display: flex;
  width: 100%;
  text-align: center;
  background-color: white;
  flex-wrap: wrap;
}
.contentBtn {
  width: 33.33%;
  border: 1px solid black;
  border-left: none;
  background-color: white;
  padding: calc(4px + 14 * (100vw / 1920));
  font-size: 16px;
  font-weight: 300;
}
.contentBtn:last-child {
  border-right: none;
}
.navMenu.top {
  width: 12%;
}
.navMenu.top ul {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.navMenu.bottom {
  width: 100%;
  display: none;
  background-color: white;
  padding: 0 1.7%;
  font-style: normal;
  font-weight: 450;
  font-size: calc(10px + 10 * (100vw / 1920));
  margin: 0 1%;
}
.headernavbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.7%;
  font-style: normal;
  font-weight: 450;
  font-size: calc(10px + 10 * (100vw / 1920));
  line-height: calc(24px + 3 * (100vw / 1920));
  text-align: center;
  margin: 0 1%;
}

/*имя коллекции на мобилке*/
.collectionMobile {
  display: none;
}

.searchInput {
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: calc(10px + 10 * (100vw / 1920));
  line-height: calc(27px * (100vw / 1920));
  text-align: center;
  width: 80px;
}

.headLine {
  border-bottom: 1px solid black;
  height: 1px;
}

/*Нижняя часть шапки*/
.collectionTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.7%;
  font-weight: 400;
  font-size: calc(10px + 10 * (100vw / 1920));
  line-height: calc(27px * (100vw / 1920));
  text-align: center;
  margin: 0 1%;
}

/*линия*/

.lineBody {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
}

.line img {
  width: 16%;
  padding: 4px 0;
}

.visionBtn {
  border: none;
  background-color: white;
  width: 60px;
  height: 60px;
}
.visionBtn img {
  width: 100%;
  height: 100%;
}

.vision {
  display: flex;
  justify-content: space-between;
  min-width: 7.5%;
}

.forCapsulFlexRow {
  width: 100%;
}

.blackBtn {
  background-color: black;
  color: white;
}
.active {
  background-color: black;
  color: white;
}

/*Адаптив*/
@media (max-width: 1600px) {
  .navMenu.top img,
  .collectionTitle img {
    height: 80%;
  }
}

@media (max-width: 1490px) {
  .navMenu.top img,
  .collectionTitle img {
    height: 70%;
  }
  .sizePl {
    width: 100%;
    margin: 1px 0;
  }
  /*.sliderBtn{
		height: 30px;
		width: 30px;
	}*/

  .contentItem.flex30 {
    font-size: calc(5px + 10 * (100vw / 1920));
  }
}
@media (max-width: 1291px) {
  .navMenu.top {
    width: 15%;
    padding-right: 20px;
  }

  .navMenu.top img,
  .collectionTitle img {
    height: 70%;
  }

  .btnIcrement {
    width: 20px;
    height: 20px;
  }
  .sizePl.pl30 {
    padding: 3%;
  }
}
@media (max-width: 1099px) {
  .navMenu.top img,
  .collectionTitle img {
    margin-right: 10%;
    height: 80%;
  }
  .vision {
    display: none;
  }
}
@media (max-width: 930px) {
  .sizeItem {
    min-width: 30%;
  }
 

  /*.sliderBtn{
	height: 40px;
	width: 40px;
}*/

  .sizeBtnsWrapper {
    max-width: 40%;
  }

  .navMenu.top {
    width: 20%;
  }

  .contentItem.flex30 {
    display: flex;
  }
}
@media (max-width: 768px) {
  .contentItemDscription li {
    padding: 0;
  }

  .navMenu.bottom ul {
    padding: 0;
    display: inline-flex;
    width: 100%;
    margin: 0;
  }
  br {
    margin: 0;
    padding: 0;
  }
  footer {
    border-top: 1px solid black;
    padding: 0;
  }
  .navMenu.bottom li {
    padding: 2% 0;
    align-items: center;
    font-size: 16px;
    justify-content: center;
  }
  .navMenu.bottom img {
    width: calc(35px + 10 * (100vw / 1920));
    height: auto;
  }
  .navMenu li {
    margin: 0;
  }
  .navMenu li:first-child {
    border-right: 1px solid black;
  }

  .navItem {
    width: 50%;
  }
  .headernavbar {
    margin: 0;
  }

  .saleinfoFlexRow {
    flex-wrap: wrap;
  }
  .saleinfo {
    height: 50px;
  }

  .mobilShow {
    width: 100%;
    font-size: 10px;
  }
  .saleinfobtnblock {
    width: 50%;
  }
  .saleinfoFlexRow {
    width: 50%;
  }

  .contentBtns {
    padding: 0 3;
  }

  .popupWrap {
    display: block;
    max-height: calc(222.75px + 668.25 * (100vh / 1000));
  }

  .popupImg img {
    width: 100%;
    height: 100%;
  }

  .sizeFlexWrap {
    justify-content: left;
  }
  header {
    padding: 0;
  }
  .line img {
    width: 23%;
    padding: 6px 0;
  }

  .collectionMobile .logo {
    display: initial;
    width: 50%;
  }
  .collectionMobile h1 {
    letter-spacing: 2pt;
  }
  header h1 {
    font-size: calc(10px + 25 * (100vw / 990));
    line-height: 57px;
    text-align: right;
    margin-right: 10px;
  }
  .contentItem {
    width: 100%;
    display: block;
  }
  .contentItemImage {
    width: 100%;
    display: block;
    height: auto;
  }
  .sizeItem {
    min-width: 11%;
    margin: 2%;
  }

  .contantItemInfo {
    width: 100%;
    display: block;
  }

  .contentItemDescription ul {
    width: 100%;
    display: inline-flex;
    justify-content: flex-start;
  }

  
 
}

/*Нижняя панель*/
.saleinfo {
  display: flex;
  background-color: white;
  height: calc(50px + 40 * (100vw / 1920));
  align-items: center;
  justify-content: space-around;
  text-align: left;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  font-size: calc(6px + 14 * (100vw / 1920));
  padding: 0 5px 0 5px;
}
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 100%;
}

/*Профиль пользовател/*/

.userProfile {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 1% 2%;
}

.userDataInput {
  width: 90%;
  height: 70px;
  font-size: calc(20px * (100vw / 1920));
  margin: 20px;
  border-radius: none;
  border: 1px solid black;
  padding: 0 40px;
}

.login-input {
  width: 88.5%;
  margin: 2% 0;
  height: 40px;
  font-size: 20px;
  text-align: center;
  border: none;
  border-bottom: 1px solid black;
}
.wrapper {
  display: flex;
  justify-content: center;
  height: 100vh;
}
.login-title img {
  width: 100%;
  height: auto;
  margin: 2% 0;
}
.login-btn {
  width: 90%;
  height: 50px;
  margin: 2% 0;
  color: white;
  background-color: black;
  border-radius: none;
  font-size: 20px;
  border: none;
}
.flex-conteiner-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.flex-conteiner {
  display: flex;
  text-align: center;
}

@media (max-width: 700px) {
  .navMenu.bottom li {
    align-items: center;
  }
  .navMenu.top.top {
    display: none;
  }
  
  .collectionTitle,
  .headLine {
    display: none;
  }
  .navMenu.bottom {
    display: inherit;
  }
  
  .mobilHide {
    display: none;
  }
  .collectionMobile {
    display: initial;
    width: 90%;
    padding: 0;
  }
  .navMenu.bottom img {
    height: calc(2px + 20 * (100vw / 320));
    margin: 0;
  }
}
