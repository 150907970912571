.ApplicationForPersonnelContent {
  width: 100%;
  min-height: 100vh;
  padding: 5%;
  background-color: white;
  font-size: calc(5px + 7 * (100vw / 1920))
  }
.bigtable {
  max-width: 70%;
}

.HaveBtn table, .PersonelFormWrapper>table{
  width: 100%;
}
.ApplicationForPersonnelContent th{
  background-color: #e9e9e9;
}
.ApplicationForPersonnelNavBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1%;
  width: 22%;
  height: 50px;
  padding: 0;
}
.ApplicationForPersonnelTable a {
  color: white;
  
}
.ApplicationForPersonnelNavBarBtn {
  border: 1px solid black;
  width: 200px;
  height: 30px;
  background-color: black;
  color: white;
  font-size: calc(5px + 7 * (100vw / 1920))
}
.ApplicationForPersonnelNavBarBtn.Active {
  background-color: white;
  color: black;
}
.ApplicationForPersonnelButtons {
  margin-bottom: 1%;
  width: 9.2vw;
  display: flex;
  justify-content: space-between;
}
.ApplicationForPersonnelButtonsBtn {
  background-color: black;
  color: white;
  min-width: 4.5vw;
  border: none;
  padding: 2% 5%;
  font-size: calc(7px + 7 *(100vw / 1920))
}
.ApplicationForPersonnelButtonsBtn.big {
  margin: 0 5px;
}

.ApplicationForPersonnelButtonsBtn.small {
  min-width: auto;
}

.ApplicationForPersonelSortSelected {
  width: 45%;
  height: 230px;
  display: flex;
  overflow: scroll;
  flex-direction: column;
  border: 1px solid black;
}
.ApplicationForPersonelSortSelected.btns {
  width: 5%;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: none;
}
.ApplicationForPersonelSort {
  display: flex;
  width: 50%;
  min-height: 100px;
  align-items: center;
  justify-content: space-between;
}

.ApplicationForPersonelSortBtns {
  width: 4%;
  margin-bottom: 1%;
  display: flex;
  justify-content: space-between;
}
.ApplicationForPersonelSortWrapper {
  width: 70%;
}
.PersonelFormWrapper {
  border: 1px solid black;
 width: 100%;

 overflow-y: scroll
}
.PersonelFormWrapper.popup{
  width: 50%;
}
.wrapperHrApplication {
  margin-top: 3%;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.buttonsTab{
  margin-left: 25%;
  margin-bottom: 15px;
  width: 30%;
  display: flex;
  position: absolute;

}
.page{
  margin-top: 24px;
  z-index: 4;
  margin: 24px 25% 2% 25%; 
}
.TabButton{
  height: 25px;
  width: 33%;
  border: 1px solid black;
  background-color: white;
}
.appPopup>td, .appPopup>th{
font-size:calc(2px + 14 * (100vw / 1920))
}
.TabButton.opn{
  border-bottom: none;
  z-index: 5;
}
.RightTable{
    overflow-y:scroll;
    width:60%;
    min-height:90px;
}
.LeftTable{
    width: 40%;
    min-height:90px;
    overflow:hiden;
}
@media (max-width:1600px) {
 
  .wrapperHrApplication{
    margin-top: 5%;
  }
}
@media (max-width:1200px) {
  .page{
    margin: 24px 15% 4% 15%; 
  }
  .buttonsTab{
    margin-left: 15%;
  }
  .LeftTable{
  width:50%;
  }
  .RightTable{width:50%;}

}

@media (max-width:1100px) {
  .page{
    margin: 24px 10% 4% 10%; 
  }
  .buttonsTab{
    margin-left: 10%;
  }
  .wrapperHrApplication{
    margin-top: 7%;
  }
}