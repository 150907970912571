.diagramm_wrapper {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 2%;
  overflow: scroll;
  background-color: white;
  padding: 5% 0;
}
.diagram_menu {
  position: fixed;
  top: 10%;
  background-color: white;
}
iframe .geMenubar {
  display: none;
}
.flex_title {
  display: flex;
  width: 100%;
  background-color: white;
  align-items: center;
  justify-content: space-around;
  margin: 0 2% 1% 0;
}
.scroll_wrapper_img {
  overflow: scroll;
  min-height: 56vh;
  background-color: white;
  padding: 2%;
}

.diagramm_wrapper h1 {
  font-size: 31px;
  line-height: 22px;
  font-weight: 200;
}
.redactionInfoSpan {
  padding: 5px;
}
.react-pdf__Page__textContent.textLayer,
.react-pdf__Page__annotations.annotationLayer {
  display: none;
}

.diagramm_wrapper a {
  color: blue;
  text-decoration: dashed;
}

.diagramm_wrapper img {
  max-width: 80%;
  height: auto;
}
.diagramm_wrapper h4 {
  font-size: calc(2px + 14 * (100vw / 1920));
  line-height: 19.07px;
  font-weight: 300;
  align-items: center;
  padding: 0;
  margin: 1%;
}
.diagramm_wrapper h1 {
  font-size: calc(2px + 20 * (100vw / 1920));
  text-align: center;
}
.diagramm_wrapper h2 {
  font-size: calc(2px + 18 * (100vw / 1920));
  margin: 1%;
}
.diagramm_wrapper h3 {
  font-size: calc(2px + 16 * (100vw / 1920));
  margin: 1%;
}
.diagramm_wrapper h5 {
  font-size: calc(2px + 14 * (100vw / 1920));
  margin: 1%;
}
.diagramm_wrapper h2 {
  font-size: calc(2px + 14 * (100vw / 1920));
  margin: 1%;
}
.diagramm_wrapper h2 {
  font-size: calc(2px + 14 * (100vw / 1920));
  margin: 1%;
}
.cm-line {
  font-size: calc(2px + 10 * (100vw / 1920));
  margin: 1%;
}
.diagram_img {
  image-rendering: optimizespeed;
  shape-rendering: crispedges;
  image-rendering: pixelated; /* Отключает сглаживание в браузерах, поддерживающих этот стиль */

}
