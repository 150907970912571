header {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: white;
  z-index: 10;
  border-bottom: 1px solid black;
}
.container {
  margin-top: 3.5%;
  height: 96.5%;
  
}

.logo img {
  height: calc(5px + 35 * (100vw / 1920));
  width: auto;
}
.wiki.logo {
}
.brg {
  width: 6vw;
  min-width: 130px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  font-size: calc(10px + 12 * (100vw / 1920));
}
.brg ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  padding: 0;
}
.brg img {
  width: calc(10px + 30 * (100vw / 1920));
  height: calc(10px + 30 * (100vw / 1920));
}
.brg:hover {
  cursor: pointer;
}

.hedLeft {
  width: 55vw;
  height: calc(10px + 30 * (100vw / 1920));
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}
.headernavbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 60px;
  padding: 5px 1.7%;
}
.navMenu.top > ul {
  display: inline-flex;
  width: 10vw;
  justify-content: space-between;
  font-size: calc(2px + 15 * (100vw / 1920));
  line-height: calc(2px + 15 * (100vw / 1920));
}

.navMenu.top.wiki img {
  height: calc(10px + 15 * (100vw / 1920));
  width: auto;
}

header li,
footer li,
.navItem {
  display: flex;
}
.navItem.bottom {
  flex-direction: column;
}
header li:hover {
  cursor: pointer;
}
button:hover {
  cursor: pointer;
}

header li img,
footer li img {
  margin-right: 10px;
  height: calc(7px + 10 * (100vw / 1920));
  width: auto;
}

.contentFlexWrapper {
  margin-top: 70px;
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  height: 90vh;
}

/*имя коллекции на мобилке*/
.collectionMobile {
  display: none;
}

/*Нижняя часть шапки*/
.collectionTitle {
  padding: 0 1.7%;
  display: flex;
  justify-content: space-between;
  max-height: 80px;
}
.searchInputBlock {
  display: inline-flex;
}
.searchInput {
  width: 45px;
  border: none;
}
.headLine {
  border-bottom: 1px solid black;
  height: 1px;
}
.vision {
  display: flex;
  justify-content: space-between;
  width: 120px;
  align-items: center;
}
.visionBtn {
  max-width: 50px;
  max-height: 50px;
  border: none;
  background-color: white;
}
.visionBtn img {
  width: 100%;
}

@media (max-width: 1355px) {
  .hedLeft {
    width: 90vw;
    height: 40px;
    align-items: center;
  }

  .navMenu.top {
    width: 20vw;
  }
  .navMenu li {
    margin-left: 20px;
    display: flex;
    align-items: center;
  }
}
