
.CapsulWrapper{
    width: 100%;
    justify-content: center;
    padding: 0 2%;
    
   
}
.CapsulWrapper span{
  font-size: calc(10px + 5 * (100vw/1920));
}
.capsulBody{
    margin: 1% 0;
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr ;
    gap: 20px;
}
.contentFlexWrapper.capsul{
    background-color:white;
    height: 100%;
    flex-wrap: wrap;
    margin-top: auto;
}
.CapsulWrapper img{
    width: 100%;
    height: auto;
}
h1{
    font-weight: 400;
}
.miniPopup .contentItem{
  width: 96%;
}
.miniPopup{
 
  background-color: white;
  z-index: 100;
  border: 1px solid black;
  position:fixed;
  top:50%;
  width:50%;
  left:50%;
  transform:translate(-50%, -50%);
  padding:0 2vw;
}





.hover:hover{
  cursor:pointer;
}
.hover{
  position: relative;
}
.LookbookBody th{
  font-size: calc(5px + 7 * (100vw / 1920));
}
.switch {
    position: relative;
    display: inline-block;
    width: calc(40px + 20 * (100vw / 1920)) ;
    height: calc(18px + 12 * (100vw / 1920)) ;
    border-radius: 45px; /* делаем скругленные углы */
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider_swich {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 60px;
   
  }
  
  .slider_swich:before {
    position: absolute;
    content: "";
    width: calc(16px + 7 * (100vw / 1920)) ;
    height: calc(16px + 7 * (100vw / 1920)) ;
    left: calc(2px + 2 * (100vw / 1920)) ;
    bottom: calc(1px + 3 * (100vw / 1920)) ;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 60px; /* делаем скругленные углы */
  }
  
  input:checked + .slider_swich {
    background-color: black;
  }
  
  input:focus + .slider_swich {
    box-shadow: 0 0 1px white;
  }
  .SwichBlock{
    display: flex;
    justify-content: flex-end;
    width: 96%;
    align-items:center;
    background-color:white;
    padding: 2%;
    min-height: 35px;

}

  
  input:checked + .slider_swich:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(28px);
    transform: translateX(28px);
  }
@media (max-width:1250px) {
   
}
@media (max-width:850px) {
  .capsulBody{
    grid-template-columns: 3fr 3fr 3fr 3fr;
    gap: calc(5px + 5 * (100vw / 670))
   }
   .miniPopup{
    width: 100%;
    left: 0;
    top: 0;
    transform: translate(0%, 0%);
    overflow-y: scroll;
    height: 100vh;
   }
   .miniPopup .contentItem,.miniPopup .sizeBoxInput, .miniPopup .catrtBtn{
    font-size: calc(8px + 2 * (100vw / 1920)) ;
  } 

}

@media (max-width:670px) {
  .SwichBlock{
    padding-top: 7%;
  }
   .capsulBody{
    grid-template-columns: 3fr 3fr;
    gap: calc(5px + 5 * (100vw / 670))
   }
    .SwichBlock{
        justify-content: center;
    }
}
@media (max-width:558px) {
  .miniPopup .contentItem,.miniPopup .sizeBoxInput, .miniPopup .catrtBtn{
    font-size: calc(5px + 28 * (100vw / 1920)) ;
  } 
    .CapsulWrapper{
        width: 100%;
        padding: 2% 10px;
    }
    input:checked + .slider_swich:before {
      transform: translateX(24px);
    }
   
}
@media (max-width:480px) {
   
  
  .opt_popup_wrapper .contentItem, .contentItem,.miniPopup .sizeBoxInput, .miniPopup .catrtBtn{
    font-size: calc(2px + 60 * (100vw / 1920)) ;
  } 
}
@media (min-width:860px) {
   
  .miniPopup .contentItem,.miniPopup .sizeBoxInput{
    font-size: calc(2px + 10 * (100vw / 1920)) ;
    padding: 0;
  } 
}


