.lookbook_table{
    max-width: 100%;
    
}
.LookbookWrapper{
    height: auto;
    position: relative;
  
}
.LookbookWrapper img{
    width: 100%;
    height: auto;
}
.LookbookWrapper td img{
    height:  calc(3px + 5 * (100vw / 1920));
    width: auto;
}
.LookbookWrapper td{
    font-size:  calc(5px + 5 * (100vw / 1920));;
}
.LookbookWrapper table{
    width: 100%;
}
.LookbookWrapper:hover{
    cursor: pointer;
}
.LookBookPageWrapper{
    width: 100%;
    min-height: 100%;
    padding: 2%  2% 0 2%;
    gap: 20px;
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr;
}
.LookBookPageWrapper td, .LookBookPageWrapper th{
    padding: 2%;
}
.CapsulWrapperPage{
    position: relative;
    margin-top: calc(5% + 90px);
    width: 100%;
    height: 100%;
}


@media (max-width:1320px) {
    .LookBookPageWrapper{
        gap: 10px;
        grid-template-columns: 2fr 2fr 2fr 2fr
    }
}
@media (max-width:780px) {
    .CapsulWrapperPage{
      margin-top:13%;
  }
  .contentFlexWrapper.capsul{
    padding-top: 5%;
    height: 100%;
  }
  }
@media (max-width:670px) {
    .LookBookPageWrapper{
        grid-template-columns: 6fr 6fr;
       
    }
    .miniPopup .sizeBox{
        width: calc(30px + 30 * (100vw / 1920));
        height: calc(30px + 30 * (100vw / 1920));
      }
    .SwichBlock{
        justify-content: center;
    }
    .LookbookWrapper table{
        display: none;
    }
}
@media (max-width:568px) {
    .CapsulWrapperPage{
        margin-top: calc(5% + 90px);
    }
    
    .LookBookPageWrapper{
        width: 100%;
        padding: 2% 10px;
    }
}
   


