.popupTree {
  align-items: center;
  padding: 20px;
  z-index: 10;
  min-height: 95vh;
  width: 70vw;
  position: absolute;
  left: 51%;
  transform: translate(-50%);
  background-color: white;
}
.popupTree textarea {
  width: 99.9%;
}
.popupTree tr {
  cursor: pointer;
}
/* .tbodyScroll {
  display: block;
  width: 100vw;
}
.tbodyScroll tr{
  width: 100vw;;
} */
.popupTree th {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  height:  calc(10px + 30 * (100vw / 1920));
}
.popupTree input[type="checkbox"] {
  margin-top: 0;
  margin-bottom: 0;
}
.leftScroll {
  overflow-x: scroll;
  z-index: 9;
  height: 100%;
}

.leftScroll thead{
  position: sticky;
  top: 0;
}

.popupTree tbody {
  margin-top: calc(5px + 10 * (100vw / 1920));
}
.popupTree td {
  text-align: left;
  white-space: nowrap;
}


.fixedItem {
  left: 0;
  display: grid;
  grid-template-columns: 3fr 3fr 3fr;
}
.popupTreeBody {
  font-size: calc(5px + 10 * (100vw / 1920));
  position: relative;
  padding: 0px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.popupTreeBody table {
  width: 100%;
}
.buttonsWrapper {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.popopColumn .border {
  width: 98%;
}
.border td{
  text-align: center;
}
.popupTwooLevel {
  width: 30vw;
  min-height: 50vh;
  background-color: white;
  border: 1px solid black;
  z-index: 11;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 30%;
  top: 10%;
  max-height: 60%;
  margin: 1% 0;
  padding: 0 2% 2% 2%;
}
.border {
  border: 1px solid gray;
  padding: 15px;
  padding-top: 0;
  width: 96.9%;
}

.popupColumnSpan {
  display: flex;
  padding: 5px 0;
  width: 100%;
  overflow: scroll;
}
.popupBtn {
  width: 3%;
  color: white;
  background-color: black;
  border: 1px solid black;
  font-size: calc(5px + 10 * (100vw / 1920));
  margin: 0;
}
.popupBtn.create {
  border-radius: 5px;
  width: auto;
}

.popupSpan {
  border: 1px solid black;
  border-radius: 0 0 0 0;
  width: 100%;
  margin: 5px;
  height: calc(5px + 20 * (100vw / 1920));
}
.popupSpan.Btn {
  border-radius: 0 0 0 0;
  width: 95.7%;
  height: calc(5px + 20 * (100vw / 1920));
  margin: 0 0 0 5px;
}
.popupColumn {
  width: 99%;
  padding: 5px;
}
.PopupPanelMenu {
  position: relative;
  z-index: 10;
  background-color: white;
  width: 20%;
  display: flex;
}
.PopupPanelMenu span {
  width: 50%;
  display: block;
  cursor: pointer;
  padding: 0 4px;
  text-align: center;
}
.popupPage {
  position: relative;
  top: -1px;
  border: 1px solid black;
  z-index: 5;
  background-color: white;
  max-height: 72vh;
  height: 72vh;
}

.h3check {
  display: flex;
  width: 100%;
}
.tableselect {
  background-color: none;
  font-size: 12px;
  border: none;
}
.tableselect > option {
  background-color: none;
}
.popupTwooLevelHeader {
  width: 100%;
  text-align: right;
  margin: 10px;
}
.popupTwooLevelHeader > button {
  border: none;
  background-color: white;
}
