.AuthBody {
  width: 100vw;
  height: 100vh;
  background-color: white;
  align-items: center;
  display: flex;
  justify-content: center;
}
.AuthBody h4{
  color: red;
}
.login-input {
  width: 78.5%;
  margin: 2% 0;
  height: 30px;
  font-size: 20px;
  text-align: center;
  border: none;
  border-bottom: 1px solid black;
}

.login-title img {
  width: 90%;
  height: auto;
  margin: 2% 0;
}
.login-btn {
  width: 80%;
  height: 30px;
  margin: 2% 0;
  color: white;
  background-color: black;
  border-radius: none;
  font-size: 20px;
  border: none;
}

.AuthBody a{
  text-decoration: none;
  color: white;
  width: 10
  0%;
}

.flex-conteiner {
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.login-form-wrapper {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
